interface FeedbackUserData {
  email?: string;
  recommendation: string;
  recommendationName: string;
  feedback: string;
  isMaoriPacific: boolean;
  location: string;
  district: string;
  role: string;
  practiceArea: string;
}

interface FormValues {
  email?: string;
  feedback: string;
}

export interface FeedbackFormErrors {
  email: string;
  feedback: string;
}

export const postFeedback = async (formData: FeedbackUserData) => {
  try {
    const response = await fetch('/.netlify/functions/submitFeedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include'
    });

    console.log("POST FEEDBACK:", formData)
    
    const data = await response.json();
    if (!response.ok) {
      console.log("ERROR", data, response);
      throw new Error(data.error || 'Submission failed');
    }

    console.log("RECIEVE FEEDBACK:", data)
    
    // Handle success
    console.log('Feedback submitted:', data);
  } catch (error) {
    // Handle error
    console.error('Error submitting feedback:', error);
  }
};

const validateForm = (values: FormValues): FeedbackFormErrors => {
  const errors: FeedbackFormErrors = {
    email: "",
    feedback: "",
  };

  if (!values.feedback?.trim()) {
    errors.feedback = "Please enter your feedback";
  }

  const emailRegex = /^[^\s@]{1,64}@[^\s@]{1,255}$/;
  if (values.email && !emailRegex.test(values.email)) {
    errors.email = "Please enter a email address";
  }

  return errors;
};

export const getValidationError = (
  values: FormValues,
  field: keyof FeedbackFormErrors
): string => {
  const errors = validateForm(values);
  return errors[field];
};
