import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import styled from "styled-components";
import CategorySelection from "./CategorySelection";
import StatelyForm from "./StatelyForm";
import handleDelayScroll from "@utils/handleDelayScroll";
import PracticePatientSelector from "./PracticePatientSelector";
import Recommendation from "./Recommendation";
import useGetStateMachine from "@hooks/useGetStateMachine";
import {
  AntibioticContext,
  PatientType,
  PracticeType,
} from "@context/antibioticContext";
import { OnboardingContext } from "@context/onboardingContext";

type Step = "Category" | "WorkFlow" | "Recommendation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding-bottom: 40px;
`;

const AntibioticsForm = () => {
  const [recommendationId, setRecommendationId] = useState("");
  const [flow, setFlow] = useState("");
  const [steps, setSteps] = useState<Step[]>([]);
  const categoryRef = useRef<HTMLDivElement>(null);
  const workFlowRef = useRef<HTMLDivElement>(null);
  const recommendationRef = useRef<HTMLDivElement>(null);

  const { handleChangeType, type } = useContext(AntibioticContext);
  const { userData } = useContext(OnboardingContext) || {};

  const { practice, patient } = type;

  const [statelyEvents, setStatelyEvents] = useState<string[]>([]);

  const [stateMachine, setStateMachine] = useState(null);

  const { stateMachine: machine, errorMessage } = useGetStateMachine(flow);

  useEffect(() => {
    if (machine) setStateMachine(machine);
  }, [machine]);

  const handleAddEvents = useCallback(
    (events: string[]) => {
      setStatelyEvents((prev) => [practice, patient, ...events]);
    },
    [patient, practice]
  );

  const handleAddStep = useCallback(
    (step: Step) => {
      setSteps((prevSteps) => {
        const stepsSet = new Set(prevSteps);
        stepsSet.add(step);
        return Array.from(stepsSet);
      });
    },
    [setSteps]
  );

  const clearAllStep = useCallback(() => {
    setSteps([]);
  }, [setSteps, steps]);

  const handleResetCategory = useCallback(
    (selectedPractice: PracticeType, selectedPatient: PatientType) => {
      if (practice !== selectedPractice || patient !== selectedPatient) {
        clearAllStep();
      }
      handleAddStep("Category");
    },
    [practice, patient, clearAllStep, setSteps, handleAddStep, steps]
  );

  const onFormFinished = useCallback(
    (context: { link: string }, events?: string[]) => {
      events && handleAddEvents(events);
      handleAddStep("Recommendation");
      setRecommendationId(context.link);
    },
    [handleAddStep, handleAddEvents]
  );

  const handleNewStart = useCallback(() => {
    clearAllStep();
  }, [clearAllStep]);

  const handleResetStateMachine = useCallback(() => {
    setRecommendationId("");
  }, []);

  const scrollToCategory = useCallback(() => {
    if (steps.includes("Category")) {
      handleDelayScroll(categoryRef);
    }
  }, [steps]);

  const scrollToStateMachine = useCallback(() => {
    if (steps.includes("WorkFlow")) {
      handleDelayScroll(workFlowRef);
    }
  }, [steps]);

  return (
    <Container>
      <PracticePatientSelector
        onClick={(practice, patient) => {
          scrollToCategory();
          handleResetCategory(practice, patient);
          handleChangeType?.(practice, patient);
        }}
        practice={practice}
        patient={patient}
      />

      {steps?.includes("Category") && (
        <div ref={categoryRef}>
          <CategorySelection
            onClick={(flowName: string) => {
              // reset statemachine
              if (flow !== flowName) {
                setStateMachine(null);
              }

              scrollToStateMachine();
              handleAddStep("WorkFlow");
              setFlow(flowName);
            }}
            onCategoriesAvailable={scrollToCategory}
          />
        </div>
      )}

      {steps?.includes("WorkFlow") && stateMachine && (
        <div ref={workFlowRef}>
          <StatelyForm
            onFormStart={scrollToStateMachine}
            stateMachine={stateMachine}
            onFormFinished={onFormFinished}
            preFiringEvents={[
              { type: practice },
              { type: patient },
              {
                type: "setLocation",
                param: {
                  district: userData?.district || "",
                  location: userData?.location || "",
                  role: userData?.role || "",
                  practiceArea: userData?.practiceArea || ""
                },
              },
            ]}
            onReset={handleResetStateMachine}
          />
        </div>
      )}

      {steps?.includes("WorkFlow") && errorMessage && (
        <div ref={workFlowRef}>{errorMessage}</div>
      )}

      {steps?.includes("Recommendation") && recommendationId && (
        <div ref={recommendationRef}>
          <Recommendation
            id={recommendationId}
            onEdit={scrollToCategory}
            onNewStart={handleNewStart}
            onRecommendationAvailable={() =>
              handleDelayScroll(recommendationRef)
            }
            events={statelyEvents}
          />
        </div>
      )}
    </Container>
  );
};

export default AntibioticsForm;
