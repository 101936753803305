import styled from "styled-components";
import ToggleInput from "./ToggleInput";
import { Button } from "@te-whatu-ora/anatomic";
import { PatientType, PracticeType } from "@context/antibioticContext";
import { useState } from "react";
import { Patient, Practice } from "@constants/antibiotics";

interface Props {
  onClick: (practice: PracticeType, patient: PatientType) => void;
  practice: PracticeType;
  patient: PatientType;
}

const TitleContainer = styled.div`
  margin-bottom: 48px;
`;

const Title = styled.h1`
  margin-bottom: 12px;
`;

const SubTitle = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const PracticePatientSelector = ({ onClick, practice, patient }: Props) => {
  const [practiceInput, setPracticeInput] = useState<PracticeType>(practice);
  const [patientInput, setPatientInput] = useState<PatientType>(patient);

  const handlePracticeOnChange = (value: string) =>
    setPracticeInput(value as PracticeType);
  const handlePatientOnChange = (value: string) =>
    setPatientInput(value as PatientType);

  return (
    <div>
      <TitleContainer>
        <Title>Review antibiotic guidelines</Title>
        <SubTitle>
          <b>Disclaimer:</b> Content only serves as educational guidelines, and does not supplant clinical judgement or Infectious Diseases & Paediatric Infectious Diseases consultation.
        </SubTitle>
      </TitleContainer>
      <InputWrapper>
        <ToggleInput
          label="Select practice type"
          defaultValue={practiceInput}
          labelLeft={Practice.PRIMARY}
          valueLeft={Practice.PRIMARY}
          labelRight={Practice.SECONDARY}
          valueRight={Practice.SECONDARY}
          onChange={handlePracticeOnChange}
        />
        <ToggleInput
          label="Select patient type"
          defaultValue={patientInput}
          labelLeft={Patient.ADULT}
          valueLeft={Patient.ADULT}
          labelRight={Patient.CHILD}
          valueRight={Patient.CHILD}
          onChange={handlePatientOnChange}
        />

        <Button
          onClick={() => {
            onClick(practiceInput, patientInput);
          }}
          disabled={!practiceInput || !patientInput}
        >
          Next
        </Button>
      </InputWrapper>
    </div>
  );
};

export default PracticePatientSelector;
