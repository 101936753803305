import styled from "styled-components";
import { ReactComponent as BackgroundCover } from "@assets/images/background.svg";
import backgroundImage from "@assets/images/background-image.jpg";
import { ReactComponent as CheckCircle } from "@assets/icons/check-circle.svg";
import { ReactComponent as Logo } from "@assets/icons/health-nz-logo.svg";
import { Button } from "@te-whatu-ora/anatomic";
import { useNavigate } from "react-router-dom";
import mediaQuery from "@utils/mediaQuery";
import Disclaimer from "@components/Disclaimer";
import {
  STORAGE_KEYS,
  getLocalStorage,
  setLocalStorage,
} from "@utils/localStorage";
import { getDiffInDays } from "@utils/date";
import { useEffect, useMemo, useState } from "react";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin: 0 auto;
  margin-bottom: 16px;
  margin-top: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  ${mediaQuery("tablet")`
    padding: 77px 100px;
    max-width: 1320px;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
  `}
`;

const Title = styled.h1`
  margin-bottom: 17px;
`;

const Subheading = styled.p`
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.4px;
  margin-bottom: 30px;

  ${mediaQuery("tablet")`
  font-size: 18px;
  `}
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${mediaQuery("tablet")`
  flex-direction: column;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-bottom: 30px;
  order: 1;
`;

const InfoText = styled.p`
  margin: 0;
  display: flex;
  gap: 8px;
  align-items: flex-start;
  color: #505e79;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.8px;

  ${mediaQuery("tablet")`
  align-items: center;
  font-size: 16px;

  span {
    display: flex;
    algin-items: center;
  }
  `}
`;

const BackgroundImageWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  z-index: 1;
  display: none;

  ${mediaQuery("desktop")`
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  display: initial;
  `}
`;

const ButtonWrapper = styled.div`
  margin-bottom: 32px;
  order: 2;

  ${mediaQuery("tablet")`
  order: 1;
  `}
`;

const ApprovalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 333px;
  height: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;

  p {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const BackgroundImageContainer = styled.div`
  display: none;

  ${mediaQuery("tablet")`
  display: block;
  svg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 200px;
  }
  `}
`;

const LeftContent = styled.div`
  flex: 1;
  z-index: 1;
`;

const Home = () => {
  const [showDiscaimer, setShowDiscaimer] = useState(false);
  const navigate = useNavigate();

  const currentDate = useMemo(() => new Date(), []);

  useEffect(() => {
    const discaimerTimeStamp = new Date(
      getLocalStorage(STORAGE_KEYS.DISCAIMER_TIME_STAMP) as string
    );

    if (discaimerTimeStamp) {
      const diffInDays = getDiffInDays(currentDate, discaimerTimeStamp);
      setShowDiscaimer(diffInDays > 1);
    } else {
      setShowDiscaimer(true);
    }
  }, [currentDate]);

  return (
    <>
      <BackgroundImageContainer>
        <BackgroundCover />
      </BackgroundImageContainer>
      <Container>
        <LeftContent>
          <Title>Aotearoa antibiotic prescribing guidelines</Title>
          <Subheading>
            <p>Supporting with education regarding antibiotic prescribing best practices, common use cases, and complex dosing.</p>
            <p><i><b>Disclaimer:</b> Content only serves as educational guidelines, and does not supplant clinical judgement or Infectious Diseases & Paediatric Infectious Diseases consultation.</i></p>
          </Subheading>
          <InfoContainer>
            <TextWrapper>
              <InfoText>
                <span>
                  <CheckCircle />
                </span>
                {/* */}
                Educational guidelines for common cases.
              </InfoText>
              <InfoText>
                <span>
                  <CheckCircle />
                </span>
                {/* */}
                Information on complex dosing.
              </InfoText>
              <InfoText>
                <span>
                  <CheckCircle />
                </span>
                {/* */}
                Contact your local ID/micro expert for advice
              </InfoText>
            </TextWrapper>
            <ButtonWrapper>
              <Button onClick={() => navigate("/guidelines")}>
                Go to guidelines
              </Button>
            </ButtonWrapper>
          </InfoContainer>
          <ApprovalContent>
            <p>
              Approved by ...
              Endorsed by ...
            </p>
            <Logo />
          </ApprovalContent>
        </LeftContent>

        <BackgroundImageWrapper>
          <img src={backgroundImage} alt="" width={"100%"} height={"100%"} />
        </BackgroundImageWrapper>

        {showDiscaimer && (
          <Disclaimer
            title="Discaimer"
            content={`The recommendations given in this guide were developed for use at TeToka Tumai (including Starships Children's Hospital). While we are happy to make them freely accessible, they may not be appropriate for other institutions. They are meant to serve as treatment guidelines and do not supplant clinical judgement or Infectious Diseases or Paediatric Infectious Diseases consultation when indicated.
    
        While we have attempted to verify that all information remains correct, because of on-going research, practice may have changed.
        
        We use analytics to improve your experience and provide personalised content.`}
            buttonText="Accept"
            buttonOnClick={() => {
              setLocalStorage(
                STORAGE_KEYS.DISCAIMER_TIME_STAMP,
                currentDate.toString()
              );
              setShowDiscaimer(false);
            }}
          />
        )}
      </Container>
    </>
  );
};

export default Home;
