import { useMachine } from "@xstate/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import RadioGroup from "./RadioGroup";
import styled from "styled-components";
import handleDelayScroll from "@utils/handleDelayScroll";
import CheckboxGroup from "./CheckBoxGroup";
import { flatMap } from "lodash";

const Wrapper = styled.div`
  margin-bottom: 100px;
`;

type Option = { value: string; label: string };

interface Question {
  question: string;
  options: Option[];
  selectedValues: string | string[];
  isMultiSelect: boolean;
  flow: string;
}

interface Props {
  stateMachine: any;
  onFormFinished?: (context?: any, events?: string[]) => void;
  preFiringEvents?: { type: string; param?: { [key: string]: string } }[];
  onReset?: () => void;
  onFormStart?: () => void;
}

/* TODO:
- Not showing selected options if you edit after completing flow
- Not resetting selected bodysystem after edit
*/

const StatelyForm = ({
  stateMachine,
  onFormFinished,
  preFiringEvents,
  onReset,
  onFormStart,
}: Props) => {
  const [current, send] = useMachine(stateMachine);

  const sendTo = send as any;

  const currentQuestionRef = useRef<HTMLDivElement>(null);
  const context = current.context;
  const [isFormFinished, setIsFormFinished] = useState(!!context.link);
  const [multiSelectedValues, setMultiSelectedValues] = useState<string[]>([]);
  const [isMultiSelectDisabled, setIsMultiSelectDisabled] = useState<Map<number, boolean>>();
  const [questions, setQuestions] = useState<Question[]>([])

  useEffect(() => {
    onFormStart?.();
  }, [onFormStart]);

  useEffect(() => {
    setIsFormFinished(!!context.link);
  }, [context.link, context.reset]);

  useEffect(() => {
    // reset state machine when component gets unmounted
    return () => {
      send({ type: "resetContext" });
      onReset?.();
    };
  }, [send, onReset]);

  useEffect(() => {
    if (isFormFinished) {
      const flattendEvents = flatMap(
        questions.map((question) => question.selectedValues)
      );
      onFormFinished?.(context, flattendEvents);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isFormFinished, context, onFormFinished]);

  const runPreFiringEvents = useCallback(() => {
    // These events will be fired on the background
    if (preFiringEvents && preFiringEvents?.length > 0) {
      preFiringEvents.forEach((event) => {
        sendTo({ type: event.type, ...event.param });
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [sendTo]);

  useEffect(() => {
    runPreFiringEvents();
  }, [runPreFiringEvents]);

  // Short form to reduce repeating long terms
  // TD -> move to a config file
  const shortformConfig: Record<string, string> = {
    "scar": "Severe Cutaneous Adverse Reaction such as SJS or TEN",
  }

  const checkForShortform = (item: string) => {
    return shortformConfig[item] || item;
  }

  const getOptions = useCallback(
    (currentValue: string, currentFlow: string) => {
      const flow = currentFlow && currentFlow === context.subflow?.id ? context.subflow : stateMachine;
      const events = flow.states[currentValue]?.on ?? {};
      const options = Object.keys(events).map((key) => {
        const entry = events?.[key]?.[0];
        const option = checkForShortform(entry?.description || key);
        return { label: option, value: key };
      });
      // console.log("GET OPTIONS:", flow, events, options);
      // console.log("GET OPTIONS - deets:", currentValue, currentFlow);
      return options;
    },
    [stateMachine.states, context.subflow]
  );

  const getTitle = useCallback(
    (currentValue: string, currentFlow: string) => {
      const flow = currentFlow && currentFlow === context.subflow?.id ? context.subflow : stateMachine;
      return flow.states[currentValue]?.description || currentValue;
    },
    [stateMachine.states, context.subflow]
  );

  const getDescription = useCallback(
    (currentValue: string, currentFlow: string) => {
      const flow = currentFlow && currentFlow === context.subflow?.id ? context.subflow : stateMachine;
      return flow.states[currentValue]?.tags || "";
    },
    [stateMachine.states, context.subflow]
  );

  const checklist: { item: string; label: string; }[] = useMemo(
    () => {
      const checkName = !context.isSubflow ? context.checkName : context.snapshot.context.checkName;
      const checklist = !context.isSubflow ? context.checklist?.[checkName] : context.snapshot.context.checklist?.[checkName]
      return checklist ?? []
    },
    [context.checklist, context.isSubflow, context.snapshot, context.checkName]
  );

  const options = useMemo(() => {
    return checklist?.map((factor: { item: string; label: string; }) => ({
      value: factor.item,
      label: checkForShortform(factor.label),
    }));
  }, [checklist]);

  const handleUpdateQuestion = useCallback(
    (
      currentEvent: string,
      currentFlow: string,
      selectedValue: string | string[],
      isMulti: boolean
    ) => {
      if (!currentEvent) {
        return;
      }
      // console.log("UPDATE QUESTION (question, isMultiSelect, flow): ", currentEvent, isMulti, currentFlow,);
      // console.log("UPDATE QUESTION - MULTI: ", isMulti);
      // console.log("QUESTION OPTIONS", questions, [...getOptions(currentEvent, currentFlow)])
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        {
          question: currentEvent,
          options: isMulti ? options : [...getOptions(currentEvent, currentFlow)],
          selectedValues: selectedValue,
          isMultiSelect: isMulti,
          flow: currentFlow,
        },
      ]);
    },
    [getOptions, options, current, context.subflow, context.isSubflow ]
  );

  const handleMultiSelectChange = useCallback(
    (selectedValues: string[]) => {
      setMultiSelectedValues([...selectedValues]);
      const sendFunction = !context.isSubflow ? sendTo : context.subflowActor.send ;
      options.forEach((option) => {        
        sendFunction({
          type: "setMulti",
          item: option.value,
          name: "factors",
          selected: selectedValues.includes(option.value),
        });
      });
    },
    [current, options, context.isSubflow, context.subflowActor]
  );

  const processQuestion = useCallback(
    (question: Question) => {
      const { isMultiSelect, selectedValues } = question;

      if (isMultiSelect) {
        handleMultiSelectChange(selectedValues as string[]);
        send({ type: "next" });
        return;
      }

      // console.log("PROCESS_QUESTION: ", question, selectedValues);

      send({ type: selectedValues as string });
    },
    [handleMultiSelectChange, send]
  );

  const handleEditQuestion = useCallback(
    (
      newValue: string | string[],
      position: number,
      isTriggeredFromMultiSelect: boolean,
      isSubflow: boolean,
    ) => {
      const currentQuestion = questions[position];
      // Reset the state machine
      send({ type: "resetContext" });

      onReset?.();

      // Run pre-fire events
      runPreFiringEvents();

      const newQuestions = questions.slice(0, position);
      setQuestions(newQuestions);

      // console.log("handleEditQuestion", currentQuestion, currentQuestion.flow, newValue, isTriggeredFromMultiSelect);

      // Determine if there are no questions left after the update
      const noQuestionsLeft = newQuestions.length === 0;

      const updateAndSend = () => {
        if (!isTriggeredFromMultiSelect) {
          send({ type: newValue as string });
          handleUpdateQuestion(
            currentQuestion.question,
            currentQuestion.flow,
            newValue,
            isTriggeredFromMultiSelect,
          );
        }
      };

      if (!noQuestionsLeft) {
        // console.log('newQuestions', newQuestions)
        newQuestions.forEach((question) => processQuestion(question));
      }

      updateAndSend();
    },
    [
      handleUpdateQuestion,
      onReset,
      processQuestion,
      questions,
      runPreFiringEvents,
      send,
      context.isSubflow,
    ]
  );

  const handleRadioChange = useCallback(
    (e: string, index: number) => {
      handleEditQuestion(e, index, false, context.isSubflow);
      handleDelayScroll(currentQuestionRef);
    },
    [handleEditQuestion]
  );

  const handleDisableCheckBox = useCallback(
    (position: number, isDisabled: boolean) => {
      setIsMultiSelectDisabled((prevMap) => {
        const newMap = new Map(prevMap);
        newMap.set(position, isDisabled);
        return newMap;
      });
    },
    []
  );

  const handleEditMultiSelect = useCallback(
    (index: number) => {
      setMultiSelectedValues([]);
      handleDisableCheckBox(index, false);
      handleEditQuestion(multiSelectedValues, index, true, context.isSubflow);
      handleDelayScroll(currentQuestionRef);
    },
    [
      setMultiSelectedValues,
      handleDisableCheckBox,
      handleEditQuestion,
      multiSelectedValues,
    ]
  );

  const handleSubmitMultiSelect = useCallback(() => {
    send({ type: "next" });
    const flow = !context.isSubflow ? current : context.snapshot
    const flowId = !context.isSubflow ? current.id : context.subflow.id
    handleUpdateQuestion(flow.value, flowId, multiSelectedValues, true);
    handleDisableCheckBox(questions.length, true);
    handleDelayScroll(currentQuestionRef);
  }, [
    send,
    current.value,
    context.flow,
    context.snapshot,
    context.subflow,
    handleUpdateQuestion,
    handleDisableCheckBox,
    questions.length,
    multiSelectedValues,
  ]);

  const renderQuestion = useCallback(
    (question: Question, index: number) => {
      const flowId = question.flow;
      // console.log("RENDER_QUESTION", question, index, flowId);
      return (
        <Wrapper key={`${question.question}_${index}`}>
          {!question.isMultiSelect ? (
            <RadioGroup
              options={question.options}
              description={getDescription(question.question, flowId)}
              title={getTitle(question.question, flowId)}
              onChange={(e) => handleRadioChange(e, index)}
              selectedValue={question.selectedValues as string}
            />
          ) : (
            <CheckboxGroup
              options={question.options as any}
              title={getTitle(question.question, flowId)}
              description={getDescription(question.question, flowId)}
              onChange={handleMultiSelectChange}
              onSubmit={() => handleEditMultiSelect(index)}
              disabled={isMultiSelectDisabled?.get(index)}
              defaultValues={multiSelectedValues}
              submitButtonLabel={isMultiSelectDisabled ? "Edit" : "Next"}
            />
          )}
        </Wrapper>
      );
    },
    [
      getTitle,
      getDescription,
      handleRadioChange,
      handleEditMultiSelect,
      handleMultiSelectChange,
      isMultiSelectDisabled,
      multiSelectedValues,
    ]
  );

  const renderCurrentQuestion = useMemo(
    () => {
      const flow = !context.isSubflow ? current : context.snapshot
      const flowId = !context.isSubflow ? current.id : context.subflow.id
      const radioOptions = getOptions(flow?.value, flowId);
      const title = getTitle(flow?.value, flowId);
      const description = getDescription(flow?.value, flowId)
      return !flow.context.isMultiSelect ? (
        <RadioGroup
          options={radioOptions}
          title={title}
          description={description}
          onChange={(e) => {
            send({ type: e });
            // console.log('renderCurrentQuestion - onChange', flow.value, flowId, e, false)
            handleUpdateQuestion(flow.value, flowId, e, false);
            handleDelayScroll(currentQuestionRef);
          }}
        />
      ) : (
        <CheckboxGroup
          options={options}
          title={title}
          description={description}
          onChange={handleMultiSelectChange}
          onSubmit={handleSubmitMultiSelect}
          submitButtonLabel="Next"
        />
      )},
    [
      handleSubmitMultiSelect,
      getTitle,
      getDescription,
      context.isMultiSelect,
      context.isSubflow,
      context.subflow,
      context.flow,
      getOptions,
      current,
      options,
      handleMultiSelectChange,
      handleUpdateQuestion,
      send,
    ]
  );

  return (
    <div>
      {questions.map(renderQuestion)}
      <div ref={currentQuestionRef}>
        {!isFormFinished && renderCurrentQuestion}
      </div>
    </div>
  );
};

export default StatelyForm;
